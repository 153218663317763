<template>
	<ConfirmDialog></ConfirmDialog>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Productos</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Paquete Productos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Paquete Productos
        </div>
        <div class="flex align-items-center text-700 flex-wrap">
          <div class="mr-5 flex align-items-center mt-3">
            <i class="pi pi-briefcase mr-2"></i>
            <span>{{ totalRegistros }} Paquete Productos</span>
          </div>
        </div>
      </div>
      <div class="flex mt-3 lg:mt-0">
        <Button
        v-if="'PaqueteProducto AgregarProducto' in auth.user.permissions"
          label="Nuevo"
          v-tooltip.top="'Nuevo Paquete Producto'"
          icon="pi pi-plus"
          class="p-button-outlined mr-2"
          @click="openNuevo"
        />
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtclientes"
        :value="paqueteproductos"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :rowHover="true"
        :filters="buscar"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Paquete Productos"
        responsiveLayout="scroll"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Paquete..."
              />
            </span>
          </div>
        </template>

        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>

        <Column
          field="id"
          header="CÓDIGO"
          :sortable="true"
          exportHeader="CÓDIGO"
        >
          {{ data.id }}
        </Column>
        <Column
          field="nombre"
          header="NOMBRE PAQUETE"
          exportHeader="NOMBRE PAQUETE"
        >
          {{ data.nombre }}
        </Column>
        <Column field="created_at" header="FECHA CREACIÓN">
          <template #body="{ data }">
            {{ formatDate(data.created_at) }}
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              v-tooltip.top="'Ver Acciones'"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>
    <PaqueteCreate
      :show="paqueteDialog"
      :paqueteproducto="paqueteproducto"
      @closeModal="ocultarDialog"
      @actualizarListado="cargarPaquetes"
    >
    </PaqueteCreate>

    <PaqueteProducto
      :show="paqueteProductoDialog"
      :producto="producto"
      @closeModal="ocultarDialog"
      @actualizarListado="cargarPaquetes"
    />
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { useAuth } from "@/stores";
import PaqueteCreate from "@/module/paqueteproductos/PaqueteCreate.vue";
import PaqueteProducto from "@/module/paqueteproductos/PaqueteProducto.vue";
import PaqueteProductoService from "@/service/PaqueteProductoService";

export default {
  components: {
    PaqueteCreate,
    PaqueteProducto,
  },
  data() {
    return {
      paqueteproductos: [],
      cargando: true,
      paqueteDialog: false,
      paqueteProductoDialog: false,
      paqueteproducto: {},
      producto: {},
      buscar: {},
      enviado: false,
      estados: [
        { label: "Inactivo", value: 0 },
        { label: "Activo", value: 1 },
      ],
      errors: {},
      totalRegistros: 0,
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  paqueteProductoService: null,

  auth: null,
  created() {
    this.auth = useAuth();
    this.paqueteProductoService = new PaqueteProductoService();
    this.initBuscar();
  },
  mounted() {
    this.cargarPaquetes();
  },
  methods: {
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "Adicionar Productos",
           disabled:
            "PaqueteProducto AgregarProducto" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-plus",
          command: () => {
            this.addProducto(datos);
          },
        },
        {
          label: "Editar",
           disabled:
            "PaqueteProducto Editar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-pencil",
          command: () => {
            this.editarPaquete(datos);
          },
        },
        {
          label: "Eliminar",
           disabled:
            "PaqueteProducto Eliminar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-trash",
          command: () => {
            this.deletePaquete(datos.id);
          },
        },
      ];
    },
    addProducto(datos) {
      // obtener los productos del paquete y enviarlos al componente de paquete producto para que se muestren en el datatable de productos del paquete
      this.paqueteProductoService.getProductosPaquete(datos).then((res) => {
        this.producto = { ...datos, paqueteproductos: res.paqueteproductos };
        this.paqueteProductoDialog = true;
        this.enviado = false;
      });

      /*   this.producto = { ...datos };
      this.paqueteProductoDialog = true; */
    },

    editarPaquete(datos) {
      this.paqueteproducto = {
        ...datos,
        estado: {
          label: datos.estado_texto,
          value: datos.estado,
        },
      };
      this.paqueteDialog = true;

      this.enviado = false;
    },
    cargarPaquetes() {
      this.paqueteProductoService.getPaqueteProductoAll().then((res) => {
        this.paqueteproductos = res.paqueteproductos;
        this.cargando = false;
      });
    },
    openNuevo() {
      this.paqueteproducto = {
        estado: { label: "Activo", value: 1 },
      };
      this.enviado = false;
      this.paqueteDialog = true;
    },
    ocultarDialog() {
      this.paqueteDialog = false;
      this.paqueteProductoDialog = false;
      this.enviado = false;
      this.errors = {};
      this.paqueteproducto = {};
    },
    deletePaquete(id) {
			this.$confirm.require({
				message: "¿Está seguro que desea eliminar el Punto de Venta?",
				header: "Confirmación",
				icon: "pi pi-exclamation-triangle",
				acceptLabel: "Si, deseo eliminar",
				rejectLabel: "No, cancelar",
				accept: () => {
					this.paqueteProductoService.deletePaqueteProducto(id)
						.then((response) => {
							if(response.status == 200){
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito",
                  detail: response.mensaje,
                  life: 3000,
                });
                this.cargarPaquetes();
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.mensaje,
                  life: 3000,
                });
              }
            })
				},
			});
		},
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      }
      return "";
    },
  },
  whatch: {
    paqueteproductos() {
      this.totalRegistros = this.paqueteproductos.length;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
